<template>
  <v-card class="v-card-reset mx-auto my-12 mt-0 mb-0 mb-0 pb-8">
    <div class="dialog-header">
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <ValidationObserver v-slot="{ invalid }">
      <v-form ref="form" lazy-validation autocomplete="off">
        <v-list-item>
          <v-list-item-content>
            <InputText
              :isForResetEmail="true"
              class="custom-input-email"
              name="email"
              :values="formValue"
              :editable="true"
              validation_label="メールアドレス"
              :validation_rules="getEmailRule"
              @onInput="onInput"
              placeholder="メールアドレス*"
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <InputText
              :isForResetEmail="true"
              class="custom-input"
              name="name_sei"
              :values="formValue"
              :editable="true"
              validation_label="姓"
              validation_rules="required|max:64"
              @onInput="onInput"
              placeholder="姓*"
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <InputText
              :isForResetEmail="true"
              class="custom-input"
              name="name_mei"
              :values="formValue"
              :editable="true"
              validation_label="名"
              validation_rules="required|max:64"
              @onInput="onInput"
              placeholder="名*"
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <Label label="合言葉"></Label>
          </v-list-item-content>
        </v-list-item>
        <InputTextArea
          :isForResetEmail="true"
          class="custom-input-quest"
          name="question"
          :values="formValue"
          :editable="true"
          validation_label="質問"
          validation_rules="required|max:255"
          @onInput="onInput"
          placeholder="質問*"
          :isResize="true"
        />
        <v-list-item>
          <v-list-item-content>
            <InputText
              :isForResetEmail="true"
              class="custom-input"
              name="answer"
              :values="formValue"
              :editable="true"
              validation_label="回答"
              validation_rules="required|max:255"
              @onInput="onInput"
              placeholder="回答*"
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-btn
              height="48px"
              color="primary"
              depressed
              :disabled="invalid"
              @click="Reset()"
              >送信</v-btn
            >
          </v-list-item-content>
        </v-list-item>
      </v-form>
    </ValidationObserver>
    <Popup width="500px" :dialog="popups.isShowDialog">
      <ConfirmDialog
        @close="closeDialog"
        @yes="RedirectToLogin()"
        :isReset="true"
        :isCenter="false"
        :title="popups.titleDialog"
        :text="popups.message"
      />
    </Popup>
  </v-card>
</template>
<script>
import { Store } from "@/store/Store.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SingleLayout from "@/components/layout/SingleLayout";
import InputText from "@/components/forms/elements/InputText.vue";
import Label from "@/components/forms/elements/Label.vue";
import Popup from "@/components/common/Popup.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import InputTextArea from "../../../components/forms/elements/InputTextArea.vue";

const STORE = "Users";

export default {
  name: "ResetMail",
  components: {
    SingleLayout,
    ValidationProvider,
    ValidationObserver,
    InputText,
    Label,
    Popup,
    InputTextArea,
    ConfirmDialog,
  },
  data: () => {
    return {
      formValue: {
        email: "",
      },
      passwordShow: false,
      popups: {
        titleDialog: "パスワードリセット",
        isShowDialog: false,
        message: "",
      },
      resResetMail: {},
    };
  },
  methods: {
    async RedirectToLogin() {
      let reset_token = this.resResetMail.data.contents.entries.reset_token;
      await Store.dispatch("Login/setResetToken", { reset_token });
      // get list user infor
      let user_id = this.resResetMail.data.contents.entries.user_id;
      const resGetUser = await Store.dispatch(
        `${STORE}/getUserDetail`,
        user_id
      );
      if (resGetUser.hasError == false) {
        let paramUpdatePassword = { ...resGetUser.data.contents.entries };
        //add field password and set reset_pass_flg = 1
        paramUpdatePassword["password"] = "12345678";
        paramUpdatePassword["reset_pass_flg"] = 1;
        //update to user
        const res = await Store.dispatch(
          `${STORE}/update`,
          paramUpdatePassword
        );
        if (res.hasError == false) {
          Store.dispatch("Toast/show", {
            status: 200,
            message: "パスワードのリセットが成功しました。",
          });
          let reset_token = null;
          await Store.dispatch("Login/setResetToken", { reset_token });
          window.location.reload();
        } else {
          Store.dispatch("Error/show", {
            status: res.status_code,
            message: "パスワードのリセットに失敗しました。",
          });
        }
      } else {
        Store.dispatch("Error/show", {
          status: resGetUser.status_code,
          message: "パスワードのリセットに失敗しました。",
        });
      }
    },
    showDialog() {
      this.popups.isShowDialog = true;
    },
    closeDialog() {
      this.popups.isShowDialog = false;
    },
    async Reset() {
      this.resResetMail = await Store.dispatch(
        "Login/resetMailWeb",
        this.formValue
      );
      if (this.resResetMail.hasError == false) {
        this.popups.message = this.resResetMail.data.message;
        this.showDialog();
      } else {
        Store.dispatch("Error/show", {
          status: this.resResetMail.status_code,
          message:
            "入力した値が正しくありません。ご確認の上、もう一度入力してください。",
        });
      }
    },
    onInput({ name, value }) {
      const formValue = { ...this.formValue };
      formValue[name] = value;
      this.formValue = formValue;
      this.$emit("formUpdate", formValue);
    },
  },
  computed: {
    getEmailRule() {
      return "max:255|email|required";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/dialog.scss";
.row-position {
  margin-top: -20px;
}
.v-form {
  padding: 0 15px 0 15px;
}
.dialog-header {
  justify-content: right;
}
.dialog-reset ::v-deep {
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    text-align: left !important;
  }
  .text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 24px;
    letter-spacing: 0.1px !important;
    color: rgba(0, 0, 0, 0.87);
    text-align: left !important;
  }
}
.custom-input,
.custom-input-email {
  ::v-deep .v-input__control {
    margin-bottom: -18px;
  }
  ::v-deep .v-input__slot {
    border: 0.5px double #e0e0e0;
    border-radius: 3.5px;
    padding: 0 26px;
    height: 54px;
    ::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
.custom-input-email {
  ::v-deep .v-input__slot {
    margin-top: 15px;
  }
}
.custom-input-quest {
  ::v-deep .v-input {
    padding-left: 17px;
    font-size: 12px;
  }
  ::v-deep .v-input__slot {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.185);
    height: 165px;
    width: 308px;
    background-color: white !important;
    ::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
</style>
