var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"pa-1 manager-edit-table-wrapper"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticStyle:{"margin-left":"30%"}},[(_vm.editable)?_c('Select',{attrs:{"name":"inspectionitems_id","values":_vm.inspectionitems,"editable":_vm.editable,"item_text":"middle_classification","item_value":"middle_classification_id","items":_vm.INSPECTION_ITEMS},on:{"onInput":_vm.onInput}}):_vm._e()],1),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isInspectionsSelected,"color":"primary"},on:{"click":_vm.register}},[_vm._v("登録 ")])],1),_c('v-data-table',{staticClass:"elevation-0 v-data-table__wrapper",style:(`height:${
            _vm.mainHeight - 75
          }px;overflow-x: hidden;overflow-y: auto;`),attrs:{"headers":_vm.LEFT_TABLE_LABELS,"items":_vm.inspections,"hide-default-footer":"","items-per-page":_vm.inspections.length,"disable-sort":"","show-select":"","item-key":"id","noDataText":"データがありません。","outlined":""},on:{"toggle-select-all":_vm.selectAllToggle},scopedSlots:_vm._u([(!_vm.editable)?{key:`item.data-table-select`,fn:function({ isSelected }){return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"readonly":true,"disabled":true}})]}}:null],null,true),model:{value:(_vm.selectedInspections),callback:function ($$v) {_vm.selectedInspections=$$v},expression:"selectedInspections"}})],1)]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"pa-1 manager-edit-table-wrapper site-manager-edit"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticStyle:{"margin-left":"400px"}},[(_vm.editable)?_c('v-btn',{attrs:{"depressed":"","small":"","outlined":"","color":"success"},on:{"click":function($event){_vm.isShowPopup = true}}},[_vm._v(" 十 フリー入力 ")]):_vm._e()],1),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isSiteInspectionsSelected,"color":"primary"},on:{"click":_vm.remove}},[_vm._v("解除 ")])],1),_c('div',{style:(`height:${
          _vm.mainHeight - 75
        }px;overflow-x: hidden;overflow-y: auto;`)},[(_vm.siteInspections.length > 0)?_c('v-data-table',{staticClass:"elevation-0 v-data-table__wrapper",attrs:{"id":_vm.customInspections.length == 0 ? null : 'top_table',"headers":_vm.RIGHT_TABLE_LABELS,"items":_vm.siteInspections,"hide-default-footer":"","items-per-page":_vm.siteInspections.length,"disable-sort":"","show-select":true,"item-key":"id","noDataText":"","outlined":""},on:{"toggle-select-all":_vm.selectAllToggle},scopedSlots:_vm._u([(!_vm.editable)?{key:`item.data-table-select`,fn:function({ isSelected }){return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"readonly":true,"disabled":true}})]}}:null],null,true),model:{value:(_vm.selectedSiteInspections),callback:function ($$v) {_vm.selectedSiteInspections=$$v},expression:"selectedSiteInspections"}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-0 v-data-table__wrapper",attrs:{"headers":_vm.RIGHT_TABLE_LABELS_NEW,"items":_vm.customInspections,"hide-default-footer":"","disable-sort":"","hide-default-header":_vm.siteInspections.length > 0 ? true : false,"items-per-page":_vm.customInspections.length,"noDataText":_vm.siteInspections.length == 0 && _vm.customInspections.length == 0
              ? 'データがありません。'
              : '',"item-key":"id","outlined":""},scopedSlots:_vm._u([(_vm.editable)?{key:`item.inspection_items`,fn:function({ item }){return [_c('v-row',[_c('v-icon',{staticClass:"ml-3",attrs:{"medium":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]),_c('div',{staticClass:"pl-10"},[_vm._v(_vm._s(item.inspection_items))])],1)]}}:null,(_vm.editable)?{key:`item.main_point`,fn:function({ item }){return [_c('div',{staticClass:"px-5"},[_vm._v(_vm._s(item.main_point))])]}}:null],null,true)})],1)],1)])],1),_c('Popup',{attrs:{"dialog":_vm.isShowPopup,"width":"480px"}},[_c('PopupAdd',{on:{"onCancel":_vm.closeItemForm,"onSubmit":_vm.submitForm}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }