import Vue from "vue";
import Vuex from "vuex";
import { machineSites } from "../../../api/modules/machineSites";

Vue.use(Vuex);

/**
 * api
 */

const ENTITY = machineSites;
export const MachineSites = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    machineSitesDetail: {},
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_MACHINE_SITE_DETAIL(state, payload) {
      state.machineSitesDetail = payload;
    },
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },
    async getMachineSitesDetail({ commit }, payload) {
      const response = await ENTITY.getMachineSitesDetail(payload);
      const entries = response.data.contents.entries;
      commit("SET_MACHINE_SITE_DETAIL", entries);
      return response;
    },
    async add(_context, payload) {
      return await ENTITY.add(payload);
    },
    async update(_context, payload) {
      return await ENTITY.update(payload);
    },
    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },
    async exportMachineFieldQRCode({ commit }, payload) {
      const response = await ENTITY.exportMachineFieldQRCode(payload);
      const entries = response.data.contents.entries;
      commit("EXPORT_MACHINE_FILE_QR_CODE", entries);
      return response;
    },
    async endUseMachineField(_context, payload) {
      console.log(_context);
      return await ENTITY.endUseMachineField(payload);
    },
    async cancelUseMachineField(_context, payload) {
      return await ENTITY.cancelUseMachineField(payload);
    },
    async getListMachineFieldName(_context, payload) {
      return await ENTITY.getListMachineFieldName(payload);
    },
    async getListMachineInspector(_context, payload) {
      return await ENTITY.getListMachineInspector(payload);
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getMachineSitesDetail: (state) => {
      return state.machineSitesDetail;
    },
  },
};
