import { render, staticRenderFns } from "./SmallFormDialog.vue?vue&type=template&id=0fba60ca&scoped=true"
import script from "./SmallFormDialog.vue?vue&type=script&lang=js"
export * from "./SmallFormDialog.vue?vue&type=script&lang=js"
import style0 from "./SmallFormDialog.vue?vue&type=style&index=0&id=0fba60ca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fba60ca",
  null
  
)

export default component.exports