import { render, staticRenderFns } from "./PopupEdit.vue?vue&type=template&id=0296af2c&scoped=true"
import script from "./PopupEdit.vue?vue&type=script&lang=js"
export * from "./PopupEdit.vue?vue&type=script&lang=js"
import style0 from "./PopupEdit.vue?vue&type=style&index=0&id=0296af2c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0296af2c",
  null
  
)

export default component.exports