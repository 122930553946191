<template>
  <v-container>
    <v-row no-gutters>
      <!-- <v-toolbar flat> -->
      <v-col cols="12" lg="2" sm="2" md="2">
        <Label label="氏名">
          <InputText
            name="user_name"
            :editable="true"
            :values="searchInputs"
            @onInput="onChangeSearchInputs"
          />
        </Label>
      </v-col>
      <v-col cols="12" lg="2" sm="2" md="2">
        <Label label="所属">
          <Select
            name="company_id"
            :editable="true"
            :values="searchInputs"
            :items="COMPANY_LIST"
            item_text="name"
            @onInput="onChangeSearchInputs"
          />
        </Label>
      </v-col>
      <v-col style="align-self: center" cols="12" lg="1" md="2" sm="2" xs="2">
        <TabSelect
          name="role"
          :items="FIELD_USER_ROLE"
          :editable="true"
          :values="searchInputs"
          @onInput="onChangeSearchInputs"
        />
      </v-col>
      <v-col style="align-self: center" cols="12" lg="1" md="3" sm="1" xs="1">
        <v-btn class="mx-2" color="primary" depressed @click="onSearch">
          検索
        </v-btn>
      </v-col>
      <!-- </v-toolbar> -->
    </v-row>
    <v-row no-gutters>
      <!-- 社員 -->
      <v-col cols="12" sm="6">
        <div class="pa-1 worker-edit-table-wrapper">
          <v-toolbar flat>
            <div class="center_title">社員</div>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isWorkersSelected"
              @click="register"
              color="primary"
              >登録</v-btn
            >
          </v-toolbar>
          <v-data-table
            v-model="selectedUsers"
            :headers="TABLE_LABELS"
            :items="users"
            :height="mainHeight - 200"
            :items-per-page="users.length"
            hide-default-footer
            disable-sort
            class="elevation-0 v-data-table__wrapper"
            show-select
            noDataText="データがありません。"
            outlined
          >
            <template v-slot:[`item.role`]="{ item }">
              {{ handleRole(item.role) }}
            </template>
            <template v-slot:[`item.name`]="{ item }">
              {{ handleNameUser(item) }}
            </template>
          </v-data-table>
        </div>
      </v-col>
      <!-- 現場 -->
      <v-col cols="12" sm="6">
        <div class="pa-1 worker-edit-table-wrapper site-worker-edit">
          <v-toolbar flat>
            <div class="center_title">現場</div>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isSiteWorkersSelected"
              @click="remove"
              color="primary"
              >解除</v-btn
            >
          </v-toolbar>
          <v-data-table
            v-model="selectedFieldUsers"
            :headers="TABLE_LABELS"
            :items="fieldUsers"
            hide-default-footer
            disable-sort
            :height="mainHeight - 200"
            :items-per-page="fieldUsers.length"
            class="elevation-0 v-data-table__wrapper"
            show-select
            noDataText="データがありません。"
            outlined
          >
            <template v-slot:[`item.role`]="{ item }">
              {{ handleRole(item.role) }}
            </template>
            <template v-slot:[`item.name`]="{ item }">
              {{ handleNameUser(item) }}
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  FORM_SITE_TABLE_LABELS,
  FIELD_USER_ROLE_ARRAY,
  FIELD_USER_ROLE,
} from "@/constants/FIELD_USER";
import TabSelect from "@/components/forms/elements/TabSelect";
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import Select from "@/components/forms/elements/Select.vue";
import { Store } from "@/store/Store.js";

const TABLE_LABELS = FORM_SITE_TABLE_LABELS;

export default {
  data() {
    return {
      // Table label
      TABLE_LABELS,
      users: [],
      fieldUsers: [],
      selectedUsers: [],
      selectedFieldUsers: [],
      COMPANY_LIST: [],
      searchInputs: {
        user_name: null,
        company_id: null,
        role: [],
        sort_value: "user_name_kana",
        sort_by: 1,
      },
      countSearch: 0,
    };
  },
  components: {
    TabSelect,
    Select,
    InputText,
    Label,
  },

  props: {
    formUpdate: Function,
    item: Object,
    mainHeight: Number,
  },

  async mounted() {
    this.countSearch = 0;
    this.getListCompany();
    /**
     * watch change fieldUsers
     */
    this.$watch(
      () => this.fieldUsers,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          const users = this.removeArray({
            from: this.users,
            remove: newValue,
          });
          this.users = users;
        }
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => this.item,
      (data) => {
        let users = data?.users || [];
        let fieldUsers = data?.fieldUsers || [];
        this.users = this.removeArray({
          from: users,
          remove: fieldUsers,
        });
        if (this.countSearch == 0) {
          this.fieldUsers = fieldUsers;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    // control btn 登録
    isWorkersSelected() {
      return this.selectedUsers?.length === 0;
    },

    // control btn 解除
    isSiteWorkersSelected() {
      return this.selectedFieldUsers?.length === 0;
    },

    FIELD_USER_ROLE() {
      return Object.keys(FIELD_USER_ROLE).map((key) => {
        return FIELD_USER_ROLE[key];
      });
    },
  },

  methods: {
    // register
    register() {
      let copyUsers = this.users;
      this.users = this.removeArray({
        from: this.users,
        remove: this.selectedUsers,
      });

      const registerUser = this.removeArray({
        from: copyUsers,
        remove: this.users,
      });

      this.fieldUsers = [...registerUser, ...this.fieldUsers];
      this.selectedUsers = [];
      this.emit();
    },

    // remove
    remove() {
      this.fieldUsers = this.removeArray({
        from: this.fieldUsers,
        remove: this.selectedFieldUsers,
      });
      this.users = [...this.users, ...this.selectedFieldUsers];
      this.selectedFieldUsers = [];
      this.emit();
    },

    // Array subtraction
    removeArray(arrs) {
      return [...arrs.from]?.filter((from) => {
        return ![...arrs.remove]?.some((remove) => {
          return remove.id === from.id;
        });
      });
    },

    emit() {
      const objNew = {};
      objNew["users"] = this.users;
      objNew["fieldUsers"] = this.fieldUsers;
      this.$emit("formUpdate", objNew);
    },
    /**
     * convert role id -> name
     */
    handleRole(company_type) {
      return company_type == FIELD_USER_ROLE_ARRAY[1].value
        ? FIELD_USER_ROLE_ARRAY[1].name
        : "";
    },
    /**
     * get full name
     * name_sei + name_mei
     */
    handleNameUser(item) {
      return `${item.name_sei} ${item.name_mei}`;
    },

    /**
     * onChangeSearchInputs
     * @param {name:String,value:String}
     */
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },
    /**
     * get data select 所有会社
     */
    async getListCompany() {
      const response = await Store.dispatch(`Company/get`);
      if (!response.hasError) {
        const { entries } = response.data.contents;
        this.COMPANY_LIST = [...entries];
        this.COMPANY_LIST.unshift({
          id: null,
          name: "",
        });
      }
    },
    async onSearch() {
      this.countSearch += 1;
      const response = await Store.dispatch(
        `Users/getListUserForAsignToFields`,
        this.searchInputs
      );
      if (!response.hasError) {
        const { entries } = response.data.contents;
        this.users = this.removeArray({
          from: entries,
          remove: this.fieldUsers,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.worker-edit-table-wrapper.site-worker-edit {
  border-left: none;
}
.center_title {
  margin-left: 50%;
  font-weight: bold;
}
</style>
